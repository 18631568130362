import { Button } from "primereact/button";
import SlackIcon from "@/assets/slack.svg?react";

const SLACK_ID = import.meta.env.VITE_SLACK_ID;

const redirect_url = `https://slack.com/oauth/v2/authorize?client_id=${SLACK_ID}&scope=app_mentions:read,channels:history,channels:join,channels:read,chat:write,chat:write.customize,chat:write.public,commands,emoji:read,im:history,im:read,im:write,im:write.invites,im:write.topic,incoming-webhook,links.embed:write,links:read,links:write,metadata.message:read,reactions:read,team.preferences:read,team:read,users.profile:read,users:read,users:read.email&user_scope=channels:history,channels:read,chat:write,im:history,im:read,im:write`;

export default function SlackIntegrationButton() {
  return (
    <a href={redirect_url}>
      <Button>
        <div className="flex gap-2">
          <SlackIcon width={20} height={20} />
          <div>Connect to Slack</div>
        </div>
      </Button>
    </a>
  );
}
