import { OrganizationSettingKey, OrganizationSettingsRecord } from "@/types";
import { toast } from "react-toastify";

export function getNotificationsTimeInitialValues({
  orgFromHour,
  orgFromMin,
  orgToHour,
  orgToMin,
  orgUpdateHour,
  orgUpdateMin,
}: {
  orgFromHour: string | number;
  orgFromMin: string | number;
  orgUpdateHour: string | number;
  orgUpdateMin: string | number;
  orgToHour: string | number;
  orgToMin: string | number;
}) {
  return {
    schedule_notification_from: `${orgFromHour?.toString()?.padStart?.(2, "0")}:${orgFromMin
      ?.toString()
      ?.padStart?.(2, "0")}`,
    schedule_notification_update: `${orgUpdateHour?.toString()?.padStart?.(2, "0")}:${orgUpdateMin
      ?.toString()
      ?.padStart?.(2, "0")}`,
    schedule_notification_to: `${orgToHour?.toString()?.padStart?.(2, "0")}:${orgToMin
      ?.toString()
      ?.padStart?.(2, "0")}`,
  };
}

export function getTime(time: string) {
  const hour = time.split(":")[0];
  const minute = time.split(":")[1];
  return {
    hour: Number(hour),
    minute: Number(minute),
  };
}

export function setTime({ hour, minute }: { hour?: string | number | null; minute?: string | number | null }) {
  return `${hour?.toString()?.padStart?.(2, "0")}:${minute?.toString()?.padStart?.(2, "0")}`;
}

export function getNotificationTime({
  fromHour,
  fromMin,
  toHour,
  toMin,
  updateHour,
  updateMin,
  orgSettings,
}: {
  fromHour?: string | number;
  fromMin?: string | number;
  toHour?: string | number;
  toMin?: string | number;
  updateHour?: string | number;
  updateMin?: string | number;
  orgSettings: OrganizationSettingsRecord;
}) {
  const { schedule_notification_from, schedule_notification_to, schedule_notification_update } =
    getNotificationsTimeInitialValues({
      orgFromHour: fromHour ?? Number(orgSettings.schedule_notification_from_hour),
      orgFromMin: fromMin ?? Number(orgSettings.schedule_notification_from_minute),
      orgToHour: toHour ?? Number(orgSettings.schedule_notification_to_hour),
      orgToMin: toMin ?? Number(orgSettings.schedule_notification_to_minute),
      orgUpdateHour: updateHour ?? Number(orgSettings.schedule_notification_update_hour),
      orgUpdateMin: updateMin ?? Number(orgSettings.schedule_notification_update_minute),
    });

  return {
    schedule_notification_from,
    schedule_notification_to,
    schedule_notification_update,
  };
}

export function setChannelNotificationsValues({
  schedule_notification_from,
  schedule_notification_to,
  // schedule_notification_update,
}: {
  schedule_notification_from: string;
  schedule_notification_to: string;
  // schedule_notification_update: string;
}) {
  const {
    schedule_notification_from_hour,
    schedule_notification_from_minute,
    schedule_notification_to_hour,
    schedule_notification_to_minute,
    // schedule_notification_update_hour,
    // schedule_notification_update_minute,
  } = setNotificationsTimeValues({
    schedule_notification_from,
    schedule_notification_to,
    // schedule_notification_update,
  });

  const notifyValues = {
    notifyFromHour: Number(schedule_notification_from_hour),
    notifyFromMin: Number(schedule_notification_from_minute),
    notifyToHour: Number(schedule_notification_to_hour),
    notifyToMin: Number(schedule_notification_to_minute),
    // notifyUpdateHour: Number(schedule_notification_update_hour),
    // notifyUpdateMin: Number(schedule_notification_update_minute),
  };

  return notifyValues;
}

export function setNotificationsTimeValues(origValues: {
  schedule_notification_from: string;
  schedule_notification_to: string;
  // schedule_notification_update: string;
}) {
  const fromHour = origValues.schedule_notification_from.split(":")[0];
  const fromMinute = origValues.schedule_notification_from.split(":")[1];

  const toHour = origValues.schedule_notification_to.split(":")[0];
  const toMinute = origValues.schedule_notification_to.split(":")[1];

  // const updateHour = origValues.schedule_notification_update.split(":")[0];
  // const updateMinute = origValues.schedule_notification_update.split(":")[1];

  const values: {
    schedule_notification_from_hour?: string;
    schedule_notification_from_minute?: string;
    schedule_notification_to_hour?: string;
    schedule_notification_to_minute?: string;
    // schedule_notification_update_hour?: string;
    // schedule_notification_update_minute?: string;
  } = {};

  if (fromHour) {
    values.schedule_notification_from_hour = fromHour.toString().replace(/^0/, "");
  }

  if (fromMinute) {
    values.schedule_notification_from_minute = fromMinute.toString().replace(/^0/, "");
  }

  if (toHour) {
    values.schedule_notification_to_hour = toHour.toString().replace(/^0/, "");
  }

  if (toMinute) {
    values.schedule_notification_to_minute = toMinute.toString().replace(/^0/, "");
  }

  // if (updateHour) {
  //   values.schedule_notification_update_hour = updateHour.toString().replace(/^0/, "");
  // }

  // if (updateMinute) {
  //   values.schedule_notification_update_minute = updateMinute.toString().replace(/^0/, "");
  // }

  return values;
}

export async function batchOrgSettingsUpdate({
  setIsLoading,
  orgSettings,
  saveOrgSettings,
  values,
  getUserData,
  showSuccessToast = true,
}: {
  setIsLoading: (isLoading: boolean) => void;
  orgSettings: OrganizationSettingsRecord;
  saveOrgSettings: (key: string, value: string) => Promise<void>;
  values: Record<OrganizationSettingKey, string>;
  getUserData: () => Promise<void>;
  showSuccessToast?: boolean;
}) {
  try {
    setIsLoading(true);
    const promises = [];
    for (const key in values) {
      const setKey = key as OrganizationSettingKey;

      if (orgSettings[setKey] === values[setKey]) continue;

      promises.push(
        saveOrgSettings(key, values[setKey]).catch((err) => {
          return Promise.reject(err?.response?.data?.replace("value", key) ?? err);
        })
      );
    }
    const results = await Promise.allSettled(promises);

    const errors = results.filter((result) => result.status === "rejected");
    if (errors?.length > 0) {
      for (const error of errors) {
        console.error({ error });
        toast.error("reason" in error ? error?.reason?.toString() : "Error saving settings");
      }
      return;
    }
    if (promises.length === 0) {
      return;
    }
    showSuccessToast && toast.success("Settings saved");
    await getUserData();
  } catch (err) {
    console.error(err);
    toast.error("Error saving settings");
  } finally {
    setIsLoading(false);
  }
}
