import { DateTime } from "luxon";
import axios from "axios";

import dayjs from "dayjs";
import { EventType, FeatureEventType, JUpcomingGreetings } from "../types";
import { FormikProps } from "formik";
import { EventDTO } from "@/containers/Events/types";
import { toast } from "react-toastify";
import clsx from "clsx";

export const toBoolean = (val: string | number | boolean) => {
  if (typeof val === "string") {
    switch (true) {
      case val === "1":
      case val.toUpperCase() === "ENABLED":
        return true;

      case val === "0":
      case val.toUpperCase() === "DISABLED":
        return false;

      default:
    }
  }

  return !!val;
};

export const fromBoolean = (val: string | number | boolean) => (val ? "ENABLED" : "DISABLED");

export const capitalize = (str: string) => str.charAt(0) + str.toLowerCase().slice(1);
export const toUpperCase = (str: string) => str.toUpperCase();

export const pluralize = (count: number, noun: string, suffix = "s") => `${count} ${noun}${count > 1 ? suffix : ""}`;

export const getToken = () => {
  const token = localStorage.getItem("token");

  return token;
};

export const setToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const removeToken = () => {
  axios.interceptors.request.use((config) => {
    config.headers.Authorization = null;

    return config;
  });

  localStorage.removeItem("token");
};

export const getInitials = (fullName?: string) => {
  if (!fullName) {
    return "";
  }

  const rgx = new RegExp(/(\p{L}{1})\p{L}+/gu, "gu");
  const matches = fullName.matchAll(rgx);
  const initials = matches ? [...matches] : [];

  return ((initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")).toUpperCase();
};

export const imageCellRender = ({
  name,
  imageUrl,
  className,
  counter,
  size = 40,
}: {
  counter?: string | number;
  className?: string;
  name?: string;
  imageUrl?: string;
  size?: number;
}) => {
  const demensionStyle = {
    width: `${size}px`,
    height: `${size}px`,
  };

  if (!imageUrl) {
    return (
      <div
        className={clsx(
          `flex items-center justify-center overflow-hidden rounded-full border-2 border-white bg-[var(--color-dark)] text-xl font-bold text-white`,
          className
        )}
        style={demensionStyle}
      >
        {counter ? counter : getInitials(name || "")}
      </div>
    );
  }

  return (
    <div style={demensionStyle} className={`overflow-hidden rounded-full border-2 border-white`}>
      <img src={imageUrl} alt={name} style={demensionStyle} />
    </div>
  );
};

export const birthdayCellRender = ({ dateOfBirth }: { dateOfBirth: string }) =>
  dayjs(dateOfBirth).format("DD MMM YYYY");

export const anniversaryCellRender = ({ anniversaryDate }: { anniversaryDate: string }) =>
  dayjs(anniversaryDate).format("DD MMM YYYY");

export const eventTypeCellRender =
  (events: EventDTO[] | null) =>
  ({ event, yearCount }: { event: EventDTO; yearCount: number }) => {
    if (!event || !events) {
      return "";
    }

    const isAnniversary = events.find((ev) => ev.title === "Anniversary")?.id === event.id;
    const eventTitle = events.find((ev) => ev.id === event.id)?.type;

    return (
      <>
        {`${eventTitle && eventTitle !== EventType.PERSONAL ? eventTitle : event.personalizedEventType} `}
        {isAnniversary && yearCount ? `, ${pluralize(yearCount, "year")}` : ``}
      </>
    );
  };

export const eventDescriptionCellRender =
  (events: EventDTO[] | null) =>
  ({ event }: { event: EventDTO; yearCount: number }) => {
    if (!event || !events) {
      return "";
    }
    return event.description;
  };

export function updatedAtCellRender<T extends { updatedAt: Date }>({ updatedAt }: T) {
  return dayjs(updatedAt).format("DD MMM YYYY");
}

export function createdAtCellRender<T extends { createdAt: Date }>({ createdAt }: T) {
  return dayjs(createdAt).format("DD MMM YYYY");
}

export function startDateCellRender<T extends { startDate: Date }>({ startDate }: T) {
  return startDate ? dayjs(startDate).format("DD MMM YYYY") : "";
}

export function endDateCellRender<T extends { endDate: Date }>({ endDate }: T) {
  return dayjs(endDate).format("DD MMM YYYY");
}

export function sortByCreated_at<T extends { created_at: Date }>(a: T, b: T) {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
}

export function sortByCreatedAt<T extends { createdAt: Date }>(a: T, b: T) {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
}

export function getFormikFromRef<T>(formikRef: React.RefObject<FormikProps<T>> | null) {
  const formik = formikRef?.current as FormikProps<T> | null;
  if (!formik) {
    throw new Error("Formik ref is not defined");
  }
  return formik;
}

export function getUserDataFromSession() {
  const userData = localStorage.getItem("userData");
  return userData as string;
}

export function setUserDataToSession(userData: string) {
  localStorage.setItem("userData", userData);
}

export function removeUserDataFromSession() {
  localStorage.removeItem("userData");
}

export function errorOrDefault(error: unknown, defaultValue: string) {
  console.error(error);
  if (typeof error === "string") {
    return error;
  }

  return defaultValue;
}

export const showError = (error: unknown, defMessage: string) => {
  console.error(error);
  if (typeof error === "string") {
    toast.error(error);
    return;
  }

  toast.error(defMessage);
};

export const nameBodyTemplate = (rawData: JUpcomingGreetings) => {
  if (!rawData?.imageUrl || !rawData?.name) {
    return <div className="flex gap-2">{rawData?.name || rawData?.event?.title || rawData?.customEventTitle}</div>;
  }

  return (
    <div className="flex items-center gap-2">
      {rawData && rawData?.imageUrl && imageCellRender(rawData)}
      <span className="whitespace-nowrap">{rawData?.name}</span>
    </div>
  );
};

export function getDayName(dayNumber: number) {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  if (dayNumber < 0 || dayNumber > 6) {
    return "Invalid day number";
  }

  return daysOfWeek[dayNumber];
}

/// INFO: PERSONAL and ONBOARDING events are groupable
export const isGroupableEvent = (events: EventDTO[] | null, curEventId?: string) => {
  if (!curEventId) return false;
  const event = events?.find((e) => e.id === curEventId);
  if (!event) return false;
  return event.type === EventType.PERSONAL || event.featureEventType === FeatureEventType.ONBOARDING;
};

export const getTimezoneTitleWithOffset = (timezone: string) => {
  const offest = DateTime.local().setZone(timezone).toFormat("ZZZZ");
  return `${timezone} (${offest})`;
};

export const isQaEnv = () => {
  const url = window.location.href;
  return url.includes("https://qa.bot.eteam.io");
};

export const isDevEnv = () => {
  const url = window.location.href;
  return url.includes("http://localhost");
};

export const isProdEnv = () => {
  const url = window.location.href;
  return url.includes("https://bot.eteam.io");
};
