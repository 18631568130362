import TypeSelector from "@/base/TypeSelector";
import { EventDTO } from "@/containers/Events/types";
import { Dropdown } from "primereact/dropdown";
import { useMemo } from "react";

interface Props {
  events: EventDTO[] | null;
  setEventId: (eventId: string) => void;
  eventId?: string | null;
  isDropdown?: boolean;
}

export default function EventsNav({ events, setEventId: setType, eventId, isDropdown = true }: Props) {
  const eventsList = useMemo(
    () =>
      events?.map(({ id: code, title: name }) => ({
        code,
        name,
      })) || [],
    [events]
  );

  if (isDropdown) {
    return (
      <div className="flex size-full flex-row items-center gap-4 overflow-x-auto">
        <span className="text-xl font-extralight">Event</span>
        <Dropdown
          showClear={false}
          id="eventId"
          title="Events"
          options={eventsList}
          optionLabel="name"
          optionValue="code"
          onChange={(e) => {
            setType(e.value);
          }}
          value={eventId}
          className="w-full max-w-[300px]"
        />
      </div>
    );
  }

  return (
    <div className="flex size-full flex-row items-center overflow-x-auto">
      {events?.map((event) => (
        <TypeSelector key={event.title} isActive={eventId === event.id} onClick={() => setType(event.id)}>
          {event.title}
        </TypeSelector>
      ))}
    </div>
  );
}
