import { timezones } from "@/constants";
import { DateTime } from "luxon";
import { useTimezoneSelect } from "react-timezone-select";

export default function useGetOffsetFormattedName() {
  const { parseTimezone } = useTimezoneSelect({ labelStyle: "original", timezones });

  return (timezone?: string) => {
    const curTimezone = timezone ?? DateTime.local().zoneName;

    const curOffset = parseTimezone(curTimezone).offset || 0;

    const isMoreThanZero = curOffset >= 0;
    return `(${isMoreThanZero ? "+" : ""}${parseTimezone(curTimezone).offset} GMT)`;
  };
}
