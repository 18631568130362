import Loader from "@/base/Loader";
import { DestinationMode } from "@/types";
import { errorOrDefault, imageCellRender, isDevEnv, isQaEnv } from "@/utils";
import { Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useCallback, useRef } from "react";
import { useDestinationsContext } from "../DestinationsContext";
import JInputText from "@/base/JInputText";
import { toast } from "react-toastify";
import { JDashboardDestination } from "../types";
import JTextArea from "@/base/JTextArea/JTextArea";

interface UpdateDialogProps {
  data: JDashboardDestination | null;
  onClose: () => void;
}

type FormikProps = {
  email: string;
  preferredName: string;
  name: string;
  dateOfBirth: string | null;
  anniversaryDate: string;
  role: string | null;
  project: string | null;
  imageUrl: string;
  mode: DestinationMode;
  messageSystemId: string;
  additionalInfo: string | null;
};

export default function UpdateDialog({ data, onClose }: UpdateDialogProps) {
  const editable = isQaEnv() || isDevEnv();
  const { isFetching, updateDestinations, getDestinations } = useDestinationsContext();
  const formikRef = useRef(null);
  const hanldeSubmit = useCallback(
    async (values: FormikProps) => {
      try {
        if (!data) {
          console.error("No team member data provided");
          return;
        }

        await updateDestinations(data.id, values);
        toast.success("Team member updated");
        onClose();
        await getDestinations();
      } catch (err) {
        console.error(err);
        toast.error(errorOrDefault(err, "Error while updating team member"));
      }
    },
    [data, getDestinations, onClose, updateDestinations]
  );

  const footerContent = (dirty: boolean, submitForm: () => void) => (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={onClose} className="p-button-text" />
      <Button
        loading={isFetching}
        label="Save"
        icon="pi pi-check"
        onClick={() => {
          submitForm();
        }}
        disabled={!dirty}
      />
    </div>
  );

  return (
    <Formik<FormikProps>
      innerRef={formikRef}
      initialValues={{
        name: data?.name || "",
        email: data?.email || "",
        dateOfBirth: data?.dateOfBirth || "",
        anniversaryDate: data?.anniversaryDate || "",
        role: data?.role || "",
        project: data?.project || "",
        imageUrl: data?.imageUrl || "",
        messageSystemId: data?.messageSystemId || "",
        mode: data?.mode || DestinationMode.BIRTHDAY_ANNIVERSARY_NOTIFY,
        additionalInfo: data?.additionalInfo || "",
        preferredName: data?.preferredName || "",
      }}
      validate={(values) => {
        const errors: Partial<Record<keyof FormikProps, string>> = {};
        const dateRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/([12][0-9]{3})$/;
        const isValidDate = (date: string): boolean => dateRegex.test(date);

        if (!values.name) {
          errors.name = "Name is required";
        }

        if (values.name && values.name.length > 100) {
          errors.name = "Name is too long";
        }

        if (!values.email) {
          errors.email = "Email is required";
        }

        if (values.email && values.email.length > 100) {
          errors.email = "Email is too long";
        }

        if (values.role && values.role.length > 100) {
          errors.role = "Role is too long";
        }

        if (values.project && values.project.length > 100) {
          errors.project = "Project is too long";
        }

        if (values.additionalInfo && values.additionalInfo.length > 1000) {
          errors.additionalInfo = "Additional info is too long";
        }

        if (values.dateOfBirth && !isValidDate(values.dateOfBirth)) {
          errors.dateOfBirth = "Invalid date format. Use MM/DD/YYYY";
        } else if (!values.dateOfBirth) {
          errors.dateOfBirth = "Birth date is required";
        }
        if (values.anniversaryDate && !isValidDate(values.anniversaryDate)) {
          errors.anniversaryDate = "Invalid date format. Use MM/DD/YYYY";
        } else if (!values.anniversaryDate) {
          errors.anniversaryDate = "Anniversary date is required";
        }

        return errors;
      }}
      enableReinitialize
      onSubmit={hanldeSubmit}
    >
      {({ dirty, submitForm, setFieldValue }) => (
        <Dialog header={"Edit team member"} visible={true} onHide={onClose} footer={footerContent(dirty, submitForm)}>
          <Loader isLoading={isFetching} />
          <div className="flex w-full gap-8">
            <div className="flex w-1/2 flex-col gap-4">
              <div className="flex items-center justify-center">
                {imageCellRender({
                  name: data?.name,
                  imageUrl: data?.imageUrl,
                  size: 200,
                })}
              </div>
              <JInputText id="name" title="Name and last name" disabled={!editable} />
              <JInputText id="preferredName" title="Preffered Name" />
              <JInputText
                disabled={!editable}
                id="dateOfBirth"
                placeholder="MM/DD/YYYY"
                title="Birth date"
                onChange={(e) => {
                  setFieldValue("dateOfBirth", e.target.value?.trim());
                }}
              />
              {editable && <JInputText id="email" title="Email" disabled={!editable} />}
            </div>

            <div className="flex w-1/2 flex-col gap-2">
              <JInputText
                disabled={!editable}
                id="anniversaryDate"
                title="Work start date"
                placeholder="MM/DD/YYYY"
                onChange={(e) => {
                  setFieldValue("anniversaryDate", e.target.value?.trim());
                }}
              />
              {editable && <JInputText id="messageSystemId" title="Message System ID" disabled={!editable} />}
              <JInputText id="role" title="Role" disabled={!editable} />
              <JInputText id="project" title="Project" disabled={!editable} />
              <JTextArea
                maxChars={1000}
                rows={7}
                placeholder="Please provide additional info about your hobbies, interests, etc. Like 'I like such books, movies, etc.'"
                id="additionalInfo"
                title="Additional info (hobbies, interests)"
              />
            </div>
          </div>
        </Dialog>
      )}
    </Formik>
  );
}
