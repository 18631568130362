import { Button } from "primereact/button";
import { useThemeContext } from "../../../theme/ThemeContext";
import { useUserContext } from "../../Users/UserContext";
import { Link, useNavigate } from "react-router-dom";
import Logo from "@/assets/logo.svg?react";
import UserIcon from "./userIcon.svg?react";
import AppTopNavMenu from "../AppTopNavMenu/AppTopNavMenu";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Menu } from "primereact/menu";
import { Tooltip } from "primereact/tooltip";

const AppTopMenu = () => {
  const { logout, isChannelManager } = useUserContext();
  const { toggleSidebar } = useThemeContext();
  const menuRef = useRef<Menu>(null);
  const menuBaseRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [curBaseRef, setCurBaseRef] = useState<HTMLDivElement | null>(null);

  const { user } = useUserContext();

  const items = [
    {
      label: "Profile",
      style: { fontSize: "12px" },
      items: [
        ...(!isChannelManager
          ? [
              {
                label: "Settings",
                style: { fontSize: "12px" },
                icon: "pi pi-refresh",
                command: () => {
                  navigate("/settings");
                },
              },
            ]
          : []),
        {
          label: "Logout",
          style: { fontSize: "12px" },
          icon: "pi pi-sign-out",
          command: () => {
            logout();
          },
        },
      ],
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (menuBaseRef.current) {
      setCurBaseRef(menuBaseRef.current);
    }
  });

  return (
    <div className=" relative z-50 flex bg-[--color-bg] px-4 py-2">
      <div className=" flex min-w-[220px] flex-1 flex-wrap">
        <span className="ml-2">
          <Link to={`/`}>
            <Logo />
          </Link>
        </span>
        <Button className="lg:hidden" icon="pi pi-bars" onClick={toggleSidebar} />
      </div>
      <div className="flex w-full items-center justify-center">
        <AppTopNavMenu />
      </div>
      <div className="flex w-max items-center justify-center gap-2 pr-2 text-sm ">
        {user?.isOrgManager && (
          <>
            <Tooltip target="#isOrgManager" position="bottom" content="Organization Manager" />
            <span className="cursor-pointer" id="isOrgManager">
              ⭐️
            </span>
          </>
        )}

        <div className="flex flex-col gap-0">
          <span className="max-w-[300px] truncate">
            {user?.firstName} {user?.lastName}
          </span>
          <span className="max-w-[300px] truncate text-gray-400">{user?.organization.title}</span>
        </div>
      </div>
      <div>
        <div ref={menuBaseRef} className=" absolute right-[200px] top-[50px]" />
        <UserIcon
          className="cursor-pointer"
          onClick={() => {
            if (menuRef.current && menuBaseRef.current) {
              menuRef.current?.toggle(menuBaseRef.current as unknown as SyntheticEvent<Element, Event>);
            }
          }}
        />
        <Menu popup model={items} ref={menuRef} id="popup_menu" appendTo={curBaseRef} />
      </div>
    </div>
  );
};

export default AppTopMenu;
