import dayjs from "dayjs";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { JUpcomingGreetings } from "../../../types";
import { nameBodyTemplate } from "@/utils";
import EventTypeIcon from "@/components/EventTypeIcon";
import { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import useHealthCheckContext from "@/containers/HealthCheck/useHealthCheck";
import { upcomingHealthCheckFor7Days } from "@/containers/HealthCheck/utils";
import { useUserContext } from "@/containers/Users/UserContext";

const UpcomingNotifications = ({ notifications }: { notifications: JUpcomingGreetings[] }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const { allHealthChecks, getAllHealthChecks } = useHealthCheckContext();
  const { isChannelManager } = useUserContext();

  useEffect(() => {
    if (isChannelManager) return;
    getAllHealthChecks();
  }, [getAllHealthChecks, isChannelManager]);

  const upcomingHealthCheck = useMemo(() => {
    const notifs = upcomingHealthCheckFor7Days({ allHealthChecks });
    const mappedHealthChecks: JUpcomingGreetings[] = notifs.map((notif) => ({
      customEventTitle: notif.title,
      upcomingDate: notif.date,
      customEventType: "HealthCheck",
    }));
    return mappedHealthChecks;
  }, [allHealthChecks]);

  const handleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const dateBodyTemplate = ({ upcomingDate }: { upcomingDate?: string }) => {
    if (!upcomingDate) {
      console.error("No upcoming date found");
      return null;
    }
    return dayjs(upcomingDate).format("DD MMM YYYY");
  };

  const channelsBodyTemplate = (rawData: JUpcomingGreetings) => {
    if (!rawData?.channelTitle) {
      return null;
    }

    return <>{rawData.channelTitle}</>;
  };

  const eventTypeBodyTemplate = (rawData: JUpcomingGreetings) => {
    if (!rawData?.event?.type && !rawData?.customEventType && !rawData?.event?.personalizedEventType) {
      return null;
    }

    return (
      <EventTypeIcon
        eventType={rawData?.event?.type}
        customEventType={rawData?.customEventType}
        personalEventType={rawData?.event?.personalizedEventType}
      />
    );
  };

  const sortNotifs = useCallback((notifications: JUpcomingGreetings[]): JUpcomingGreetings[] => {
    return [...notifications].sort((a, b) => dayjs(a.upcomingDate).diff(dayjs(b.upcomingDate)));
  }, []);

  const data = useMemo(
    () => [...sortNotifs(notifications), ...upcomingHealthCheck],
    [notifications, sortNotifs, upcomingHealthCheck]
  );

  const isNoData = data.length === 0;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex cursor-pointer items-center gap-4" onClick={handleCollapse}>
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <span className={clsx("pi !text-[var(--color-orange)]", isCollapsed ? "pi-plus" : "pi-minus")} />
        <span className="font-bold">Upcoming events</span>
      </div>
      {isNoData && !isCollapsed && <span className="mt-2">No upcoming events</span>}
      {!isNoData && !isCollapsed && (
        <DataTable value={data} sortMode="single" sortField="event.type" scrollable scrollHeight="400px">
          <Column field="name" header="Team member(s)/Title" body={nameBodyTemplate}></Column>
          <Column field="type" header="Event type" body={eventTypeBodyTemplate}></Column>
          <Column field="upcomingDate" header="Event date" body={dateBodyTemplate}></Column>
          <Column header="Channel" field="channelTitle" body={channelsBodyTemplate}></Column>
        </DataTable>
      )}
    </div>
  );
};

export default UpcomingNotifications;
