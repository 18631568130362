import { ChannelDTO } from "./containers/Channels/types";
import { EventDTO } from "./containers/Events/types";

export type TodayGreetingStatus = "PLANNED" | "INPROGRESS" | "PASSED" | "FAILED" | "DECLINED" | "WAITING";

export type JApiPaginatedResponse<T> = {
  page: number;
  limit: number;
  total: number;
  data: T[];
};

export type JUpcomingGreetings = {
  name?: string;
  email?: string;
  imageUrl?: string;
  description?: string;
  upcomingDate: string;
  yearCount?: number;
  event?: EventDTO;
  channelTitle?: string;
  customEventType?: string;
  customEventTitle?: string;
};

export type JTodayGreetings = {
  id: string;
  name?: string;
  email?: string;
  dateOfBirth?: string;
  anniversaryDate?: string;
  imageUrl?: string;
  status: TodayGreetingStatus;
  details: string;
  messageId: string;
  messageTitle: string;
  customMessageContent: string | null;
  resourceId: string;
  resourceItemName: string;
  resourceUrlMin: string;
  createdAt: string;
  updatedAt: string;
  channelTitle: string;
  channelId: string;
  event: EventDTO;
  isGrouped: boolean;
  rebroadcastSlackChannelId: string | null;
  rebroadcastNotifyOnly: boolean;
};

export interface Asset {
  id: string;
  image_name: string;
  title: string;
  description: string;
  type: string;
  s3_url: string;
  s3_bucket_name: string;
  s3_object_key: string;
  created_at: string;
  updated_at: string;
  file_size: number;
  content_type: string;
  metadata: unknown;
  event: EventDTO;
}

export type ModalOperationType = "create" | "update" | null;

export enum OrganizationSettingKey {
  SLACK_API_KEY = "slack_api_key",
  SLACK_WORKSPACE_ID = "slack_workspace_id",
  SLACK_WORKSPACE_TITLE = "slack_workspace_title",
  SCHEDULE_NOTIFICATION_FROM_HOUR = "schedule_notification_from_hour",
  SCHEDULE_NOTIFICATION_FROM_MINUTE = "schedule_notification_from_minute",
  SCHEDULE_NOTIFICATION_TO_HOUR = "schedule_notification_to_hour",
  SCHEDULE_NOTIFICATION_TO_MINUTE = "schedule_notification_to_minute",
  SCHEDULE_NOTIFICATION_UPDATE_HOUR = "schedule_notification_update_hour",
  SCHEDULE_NOTIFICATION_UPDATE_MINUTE = "schedule_notification_update_minute",
  SCHEDULE_DEF_MAX_INTERVAL_BETWEEN_NOTIFICATIONS_SEC = "schedule_def_max_interval_between_notifications_sec",
  BAMBOO_API_KEY = "bamboo_api_key",
  BAMBOO_WORKSPACE_ID = "bamboo_workspace_id",
  TIMEZONE = "timezone",
  MESSAGES_FREE_LIMIT = "messages_free_limit",
  GIFS_FREE_LIMIT = "gifs_free_limit",
  IS_NOTIFICATIONS_ENABLED = "is_notifications_enabled",
  IS_BAMBOO_ALREADY_IMPORTED = "is_bamboo_already_imported",
  FEATURE_FLAG_SHARED_CUSTOM_EVENT = "feature_flag_shared_custom_event",
  BAMBOO_ROLE_FIELD = "bamboo_role_field",
  BAMBOO_PROJECT_FIELD = "bamboo_project_field",
  FEATURE_FLAG_PERSONALIZED_MESSAGE = "feature_flag_personalized_message",
  FEATURE_FLAG_HEALTH_CHECK = "feature_flag_health_check",
  FEATURE_FLAG_ONBOARDING_MESSAGE = "feature_flag_onboarding_message",
  FEATURE_FLAG_TTN = "feature_flag_ttn",
  SYSTEM_PROMPT = "system_prompt",
  SYSTEM_PROMPT_PERSONALIZED_MESSAGE = "system_prompt_personalized_message",
  SYSTEM_PROMPT_HEALTHCHECK = "system_prompt_healthcheck",
}

export interface OrganizationSettingsRecord {
  bamboo_api_key: string;
  bamboo_workspace_id: string;
  gifs_free_limit: number;
  is_bamboo_already_imported: boolean;
  is_notifications_enabled: boolean;
  messages_free_limit: number;
  schedule_def_max_interval_between_notifications_sec: number;
  schedule_notification_from_hour: number;
  schedule_notification_from_minute: number;
  schedule_notification_to_hour: number;
  schedule_notification_to_minute: number;
  schedule_notification_update_hour: number;
  schedule_notification_update_minute: number;
  slack_api_key: string;
  slack_workspace_id: string;
  slack_workspace_title: string;
  timezone: string;
  bamboo_role_field: string;
  bamboo_project_field: string;
  feature_flag_personalized_message: boolean;
  feature_flag_health_check: boolean;
  feature_flag_shared_custom_event: boolean;
  feature_flag_onboarding_message: boolean;
  feature_flag_ttn: boolean;
  system_prompt: string;
  system_prompt_healthcheck: string;
  system_prompt_personalized_message: string;
}

export interface OrganizationSettingDTO {
  id: string;
  key: OrganizationSettingKey;
  value: string;
  organization_id: string;
}

export interface NotificationUpdateParams {
  messageId: string;
  resourceId: string;
}

export interface DestinationUpdateParams {
  name: string;
  email: string;
  dateOfBirth: string;
  anniversaryDate: string;
  imageUrl: string;
  status: string;
  details: string;
  messageId: string;
  messageTitle: string;
  messageType: string;
  resourceId: string;
  resourceItemName: string;
  resourceUrlMin: string;
  createdAt: string;
  updatedAt: string;
}

export enum DestinationMode {
  BIRTHDAY_ONLY_NOTIFY = "BIRTHDAY_ONLY_NOTIFY",
  ANNIVERSARY_ONLY_NOTIFY = "ANNIVERSARY_ONLY_NOTIFY",
  BIRTHDAY_ANNIVERSARY_NOTIFY = "BIRTHDAY_ANNIVERSARY_NOTIFY",
  DISABLED = "DISABLED",
}

export enum NotificationStatus {
  PLANNED = "PLANNED",
  INPROGRESS = "INPROGRESS",
  PASSED = "PASSED",
  FAILED = "FAILED",
  DECLINED = "DECLINED",
  WAITING = "WAITING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface DropdownOption {
  name: string;
  id: string;
}

export enum EventType {
  PERSONAL = "PERSONAL",
  GLOBAL = "GLOBAL",
  FEAUTRE = "FEAUTRE",
}

export enum OccurrenceType {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
  DAILY = "DAILY",
  ONCE = "ONCE",
  CUSTOM = "CUSTOM",
}

export enum PersonalizedEventType {
  BIRTHDAY = "BIRTHDAY",
  ANNIVERSARY = "ANNIVERSARY",
}

export enum FeatureEventType {
  ONBOARDING = "ONBOARDING",
  TTN = "TTN",
  HEALCH_CHECK = "HEALCH_CHECK",
}

export interface EventChannelJoinDTO {
  id: string;
  occurrenceType: OccurrenceType | null;
  featureEventType: FeatureEventType | null;
  customOccurrenceDay: number;
  customOccurrenceWeek: number;
  startDate: string;
  endDate: string;
  event: EventDTO;
  channel: ChannelDTO;
}

export interface DestinationData {
  id: string;
  name: string;
  email: string;
  project: string;
  role: string;
  imageUrl: string;
  dateOfBirth: string;
}

export interface Destination {
  id: string;
  mode: DestinationMode;
  messageSystemId: string;
  anniversaryDate: string;
  destinationData: DestinationData;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}
