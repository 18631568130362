import { useMemo, useState } from "react";
import DestinationService from "./api";
import { JDestinationPatchBody, JDashboardDestinations, SlackChannel } from "./types";
import { toast } from "react-toastify";
import { errorOrDefault } from "@/utils";

const useDestination = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [destinations, setDestinations] = useState<JDashboardDestinations["destinations"] | null>(null);
  const [channels, setChannels] = useState<SlackChannel[]>([]);

  const getSlackChannelsInfo = async () => {
    setIsFetching(true);

    try {
      if (channels.length > 0) {
        // Cache channels
        // If we already have channels, we don't need to fetch them again
        setIsFetching(false);
        return;
      }

      const response = await DestinationService.getSlackChannelsInfo();
      setChannels(response.data);
    } finally {
      setIsFetching(false);
    }
  };

  const getDestinations = async (page = 0, limit = 999, search = "") => {
    try {
      setIsFetching(true);
      const response = await DestinationService.get(page, limit, search);
      setDestinations([...response.data]);
    } catch (err) {
      console.error(err);
      toast.error(errorOrDefault(err, "Error while fetching destinations"));
    } finally {
      setIsFetching(false);
    }
  };

  const updateDestinations = async (id: string, body: JDestinationPatchBody) => {
    try {
      setIsFetching(true);
      await DestinationService.update(id, body);
    } finally {
      setIsFetching(false);
    }
  };

  const deleteDestination = async (id: string) => {
    try {
      setIsFetching(true);
      await DestinationService.deleteDestination(id);
    } finally {
      setIsFetching(false);
    }
  };

  const importDestinations = async (body: FormData, channelId: string) => {
    try {
      setIsFetching(true);
      await DestinationService.importCSV(body, channelId);
    } finally {
      setIsFetching(false);
    }
  };

  const importDestinationsFromBambooHr = async () => {
    try {
      setIsFetching(true);
      await DestinationService.importBambooHr();
    } finally {
      setIsFetching(false);
    }
  };

  return useMemo(
    () => ({
      importDestinationsFromBambooHr,
      isFetching,
      destinations,
      getDestinations,
      updateDestinations,
      importDestinations,
      getSlackChannelsInfo,
      deleteDestination,
      channels,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching, destinations, channels]
  );
};

export default useDestination;
