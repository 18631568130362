import PageTitleWrapper from "@/components/PageTitleWrapper";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useUserContext } from "../Users/UserContext";
import { useAiAssistantContext } from "./AiAssistantContext";
import AiAssistantAddKnowledgeBaseDialog from "./AiAssistantAddKnowledgeBaseDialog";
import { useEffect, useRef, useState } from "react";
import { ModalOperationType } from "@/types";
// import Trash from "@/assets/trash.svg?react";
import Edit from "@/assets/edit.svg?react";
import AiAssistantEditKnowledgeBaseDialog from "./AiAssistantEditKnowledgeBaseDialog";
import SetupAiAssistant from "./SetupAiAssistant";
import Loader from "@/base/Loader";
import { AiAssistantFile } from "./types";

export default function AiAssistant() {
  const { isChannelManager } = useUserContext();
  const { files, getFiles, getStatus, isModelsProcesings, ...aiAssistantContext } = useAiAssistantContext();
  const [modalOperation, setModalOperation] = useState<ModalOperationType>(null);
  const [selectedFile, setSelectedFile] = useState<AiAssistantFile | null>(null);
  const pullingRef = useRef<NodeJS.Timeout | null>(null);
  const isFetching = aiAssistantContext.isGlobalFetching || aiAssistantContext.isFetching;

  useEffect(() => {
    getFiles();
    getStatus();

    if (pullingRef.current) {
      clearInterval(pullingRef.current);
    }
    pullingRef.current = setInterval(() => {
      getStatus();
    }, 5000);

    return () => {
      if (pullingRef.current) {
        clearInterval(pullingRef.current);
      }
    };
  }, [getFiles, getStatus]);

  // const confirm = (file: AiAssistantFile) => {
  //   const confirmationMessageRef = document.getElementById("confirmMessage");
  //   if (!confirmationMessageRef) {
  //     console.error("Confirmation message is not defined");
  //     return;
  //   }

  //   confirmPopup({
  //     target: confirmationMessageRef,
  //     message: "Are you sure you want to proceed?",
  //     icon: "pi pi-exclamation-triangle",
  //     accept: async () => {
  //       try {
  //         await deleteFile(file.file);
  //         toast.success("File deleted");
  //       } catch (err) {
  //         console.error(err);
  //         toast.error(errorOrDefault(err, "Error deleting file"));
  //       } finally {
  //         await getFiles();
  //       }
  //     },
  //     reject: () => null,
  //   });
  // };

  const isAnyData = files && files.length > 0;

  return (
    <div className="size-full">
      <PageTitleWrapper
        row1={
          <div className="flex items-baseline justify-center gap-4">
            <span className="text-[40px]">AI Assistant</span>
            {isModelsProcesings ? (
              <span className="text-[16px] font-bold text-[#FFC107]">○ Processing...</span>
            ) : (
              <span className="text-[16px] font-bold text-green-400">○ Ready</span>
            )}
          </div>
        }
        row2={
          isAnyData &&
          !isChannelManager && (
            <Button
              tooltip={""}
              tooltipOptions={{ position: "left" }}
              label="Upload new document(s)"
              icon="pi pi-plus"
              onClick={() => {
                setModalOperation("create");
              }}
              className="ml-5"
            />
          )
        }
      />
      <Loader isLoading={isFetching} />
      {isAnyData ? (
        <DataTable value={files}>
          <Column field="file" header="Name"></Column>
          <Column
            field="url"
            header="Link"
            body={(dataRow) => {
              return (
                <span className="break-words">
                  <a href={dataRow} target="_blank" rel="noreferrer">
                    {dataRow.url}
                  </a>
                </span>
              );
            }}
          ></Column>
          <Column field="description" header="Description"></Column>
          <Column field="last_modified" header="Created at"></Column>
          {/* <Column
            field="description"
            header="Status"
            body={() => {
              return <div className="font-bold text-[#2ADB1B]">○ ACTIVE</div>;
            }}
          ></Column> */}
          <Column
            header="Operations"
            body={(rawData) => {
              return (
                <div className="flex items-center justify-end gap-4">
                  <Edit
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedFile(rawData);
                      setModalOperation("update");
                    }}
                  />
                  {/*<Trash className="cursor-pointer" onClick={() => confirm(rawData)} /> */}
                </div>
              );
            }}
          ></Column>
        </DataTable>
      ) : (
        !isFetching && (
          <div className="mt-[100px] flex size-full items-center justify-center">
            <SetupAiAssistant
              onClick={() => {
                setModalOperation("create");
              }}
            />
          </div>
        )
      )}
      {modalOperation === "create" && (
        <AiAssistantAddKnowledgeBaseDialog
          onClose={() => {
            setModalOperation(null);
          }}
        />
      )}
      {selectedFile && modalOperation === "update" && (
        <AiAssistantEditKnowledgeBaseDialog
          onClose={() => {
            setModalOperation(null);
          }}
          data={selectedFile}
        />
      )}
    </div>
  );
}
