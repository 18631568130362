import Loader from "@/base/Loader";
import { Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { useRef } from "react";
import { toast } from "react-toastify";
import { errorOrDefault, getFormikFromRef } from "@/utils";
import { useAiAssistantContext } from "./AiAssistantContext";
import { Button } from "primereact/button";
import JInputText from "@/base/JInputText";
import { AiAssistantFile } from "./types";

const AiAssistantEditKnowledgeBaseDialog = ({ onClose, data }: { onClose: () => void; data: AiAssistantFile }) => {
  const { isFetching, editFile } = useAiAssistantContext();

  const formikRef = useRef(null);

  const onSubmit = async (values: AiAssistantFile) => {
    try {
      editFile(values);
      toast.success("File modifed");
      onClose();
      return;
    } catch (err) {
      toast.error(errorOrDefault(err, "Error saving file"));
    }
  };

  const footerContent = (dirty: boolean) => (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={() => onClose()} className="p-button-text" />
      <Button
        disabled={!dirty}
        label={"Edit"}
        icon="pi pi-check"
        onClick={() => {
          const formik = getFormikFromRef(formikRef);
          formik.submitForm();
        }}
        loading={isFetching}
      />
    </div>
  );

  return (
    <Formik<AiAssistantFile>
      innerRef={formikRef}
      initialValues={data}
      enableReinitialize
      onSubmit={onSubmit}
      validate={() => {
        const errors: Record<string, string> = {};

        return errors;
      }}
    >
      {({ dirty }) => (
        <Dialog header={"Edit file"} visible={true} onHide={onClose} footer={footerContent(dirty)}>
          <Loader isLoading={isFetching} />
          <div className="py-4">
            <JInputText id="file" title="Name" disabled />
            <JInputText id="description" title="Description" />
            <JInputText id="url" title="Link" />
          </div>
        </Dialog>
      )}
    </Formik>
  );
};

export default AiAssistantEditKnowledgeBaseDialog;
