import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useMemo, useState } from "react";
import { anniversaryCellRender, birthdayCellRender, imageCellRender } from "../../../utils";
import { useUserContext } from "../../Users/UserContext";
import { Navigate } from "react-router-dom";
import { DestinationMode } from "@/types";
import { JDashboardDestination } from "../types";
import Loader from "@/base/Loader";
import { useDestinationsContext } from "../DestinationsContext";
import { MultiSelect } from "primereact/multiselect";
import ImportDestinationsFromCSV from "@/components/ImportDestinationsFromCSV";
import ImportDestinationsFromBamboo from "@/components/ImportDestinationsFromBamboo";
import { Checkbox } from "primereact/checkbox";
import Edit from "@/assets/edit.svg?react";
// import Trash from "@/assets/trash.svg?react";
import UpdateDialog from "./UpdateDialog";
// import { confirmPopup } from "primereact/confirmpopup";

interface ChannelOption {
  name: string;
  code: string;
}

const DestinationList = () => {
  const [updateDialogData, setUpdateDialogData] = useState<JDashboardDestination | null>(null);
  const [selectedChannels, setSelectedChannels] = useState<ChannelOption[]>([]);

  const { destinations, getDestinations, updateDestinations, isFetching } = useDestinationsContext();
  const { isSuperAdmin, channels, getChannels, setChannels } = useUserContext();

  const [channelOptions, setChannelOptions] = useState<ChannelOption[]>([]);

  // const confirm = (data: JDashboardDestination) => {
  //   const confirmationMessageRef = document.getElementById("confirmMessage");
  //   if (!confirmationMessageRef) {
  //     console.error("Confirmation message is not defined");
  //     return;
  //   }

  //   confirmPopup({
  //     target: confirmationMessageRef,
  //     message: "Are you sure you want to proceed?",
  //     icon: "pi pi-exclamation-triangle",
  //     accept: async () => {
  //       try {
  //         await deleteDestination(data.id);
  //         toast.success("Team member deleted");
  //       } catch (err) {
  //         console.error(err);
  //         toast.error(errorOrDefault(err, "Error deleting team member"));
  //       } finally {
  //         await getDestinations();
  //       }
  //     },
  //     reject: () => null,
  //   });
  // };

  useEffect(() => {
    const fn = async () => {
      const newChannels = await getChannels();
      await getDestinations();

      setChannelOptions([
        ...newChannels.map((channel) => ({
          name: channel.channelTitle,
          code: channel.channelId,
        })),
      ]);
    };

    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedChannels.length > 0) {
      setChannels(channels.filter((channel) => selectedChannels.map((item) => item.code).includes(channel.channelId)));
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannels]);

  const getNotificationValues = (str: string) => str.replace("_NOTIFY", "").split("_");

  const getNotifyMode = (values: string[]): DestinationMode => {
    if (values.length === 0) {
      return DestinationMode.DISABLED;
    }

    if (values.includes("BIRTHDAY") && values.includes("ANNIVERSARY")) {
      return DestinationMode.BIRTHDAY_ANNIVERSARY_NOTIFY;
    }

    if (values.includes("BIRTHDAY")) {
      return DestinationMode.BIRTHDAY_ONLY_NOTIFY;
    }

    if (values.includes("ANNIVERSARY")) {
      return DestinationMode.ANNIVERSARY_ONLY_NOTIFY;
    }

    return DestinationMode.DISABLED;
  };

  const nameBodyTemplate = (rawData: JDashboardDestination) => (
    <div className=" flex items-center gap-3">
      {imageCellRender(rawData)}
      {rawData.name}
    </div>
  );

  const channelsBodyTemplate = (rawData: JDashboardDestination) => {
    const channels = rawData.channels.map((channel) => `#${channel.channelTitle}`);
    return (
      <div className="max-w-[350px]">
        <span className="">{channels.join(", ")}</span>
      </div>
    );
  };

  const notificationBodyTemplate = ({ id, mode }: JDashboardDestination) => {
    const notificationMode = getNotificationValues(mode);
    const isBirthday = notificationMode.includes("BIRTHDAY");
    const isAnniversary = notificationMode.includes("ANNIVERSARY");

    return (
      <div className="flex gap-4">
        <div className="flex gap-2">
          <Checkbox
            value={isBirthday}
            checked={isBirthday}
            onChange={async (e) => {
              let newNotificationMode = [...notificationMode];
              if (e.target.checked) {
                newNotificationMode.push("BIRTHDAY");
              } else {
                newNotificationMode = newNotificationMode.filter((item) => item !== "BIRTHDAY");
              }

              await updateDestinations(id, {
                mode: getNotifyMode(newNotificationMode as string[]),
              });
              await getDestinations();
            }}
          />
          Birthday
        </div>
        <div className="flex gap-2">
          <Checkbox
            value={isAnniversary}
            checked={isAnniversary}
            onChange={async (e) => {
              let newNotificationMode = [...notificationMode];
              if (e.target.checked) {
                newNotificationMode.push("ANNIVERSARY");
              } else {
                newNotificationMode = newNotificationMode.filter((item) => item !== "ANNIVERSARY");
              }

              await updateDestinations(id, {
                mode: getNotifyMode(newNotificationMode as string[]),
              });
              await getDestinations();
            }}
          />
          Anniversary
        </div>
      </div>
    );
  };

  /**
  const representativesItemTemplate = (option: ChannelOption) => {
    return <span>{option.name}</span>;
  };
  */

  const channelsFilterRender = (
    <MultiSelect
      value={selectedChannels}
      options={channelOptions}
      maxSelectedLabels={10}
      className="w-full max-w-[600px]"
      onChange={(e) => {
        const newSelectedChannels = e.value;
        setSelectedChannels(newSelectedChannels);
      }}
      optionLabel="name"
      placeholder="Select channels"
      display="chip"
    />
  );

  const finalDests = useMemo(() => {
    const dests =
      destinations?.map((d) => ({
        ...d,
        _dateOfBirth: d.dateOfBirth ? new Date(d.dateOfBirth).getTime() : 0,
        _anniversaryDate: d.anniversaryDate ? new Date(d.anniversaryDate).getTime() : 0,
      })) || [];

    if (selectedChannels.length > 0) {
      return dests.filter((d) =>
        d.channels.map((c) => c.channelId).some((c) => selectedChannels.map((item) => item.code).includes(c))
      );
    }

    return dests;
  }, [destinations, selectedChannels]);

  if (isSuperAdmin) {
    return <Navigate to={"/messages"} state={{ returnUrl: window.location.pathname }} replace />;
  }

  return (
    <div className="flex flex-col gap-4">
      <Loader isLoading={isFetching} />

      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center justify-center gap-4">
          <span className="text-[40px]">Team</span>
        </div>
        <div className=" flex">
          <ImportDestinationsFromBamboo />
          <ImportDestinationsFromCSV />
        </div>
      </div>
      <div>{channelsFilterRender}</div>
      <DataTable<JDashboardDestination[]>
        sortField="name"
        sortOrder={1}
        value={finalDests}
        /** 
        selection={selectedRows}
        onSelectionChange={(e: DataTableSelectionChangeEvent<JDashboardDestination[] | []>) =>
          setSelectedRows(e.value as JDashboardDestination[])
        }
        rows={limit} 
        */
      >
        {/** <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column> */}
        <Column sortable field="name" header="Name" body={nameBodyTemplate}></Column>
        <Column header="Channels" body={channelsBodyTemplate}></Column>
        <Column sortable field="_dateOfBirth" header="Birthday" body={birthdayCellRender}></Column>
        <Column sortable field="_anniversaryDate" header="Anniversary" body={anniversaryCellRender}></Column>
        <Column sortable field="role" header="Role"></Column>
        <Column sortable field="project" header="Project"></Column>
        <Column field="mode" header="Enabled notifications" body={notificationBodyTemplate}></Column>
        <Column
          header="Operations"
          body={(rawData) => {
            return (
              <div className="flex items-center justify-end gap-4">
                <Edit
                  className="cursor-pointer"
                  onClick={() => {
                    setUpdateDialogData(rawData);
                  }}
                />
                {/* <Trash className="cursor-pointer" onClick={() => confirm(rawData)} /> */}
              </div>
            );
          }}
        ></Column>
        {/* <Column field="mode" header="Health Check" body={healthCheckBodyTemplate}></Column> */}
      </DataTable>

      {/**
      <Paginator
        rows={limit}
        totalRecords={destinations?.total ?? 0}
        rowsPerPageOptions={[20, 50, 100, 200]}
        onPageChange={onPaginatorChange}
      /> 
      */}

      {updateDialogData && (
        <UpdateDialog
          data={updateDialogData}
          onClose={() => {
            setUpdateDialogData(null);
          }}
        />
      )}
    </div>
  );
};

export default DestinationList;
