import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { AiAssistantFile, AiAssistantStatusResp } from "./types";
import axios from "axios";
import { LoadingStatus } from "./AiAssistantAddKnowledgeBaseDialog";
import { toast } from "react-toastify";

export const AiAssistantContext = createContext<ReturnType<typeof useAiAssistant> | null>(null);

const AI_ASSISTANT_API_KEY = "9VkTjbTwG0pBbJ1x6zXJZUlW6jLi3ro3";
const AI_ASSISTANT_BASE_URL =
  "https://foujkylyih.execute-api.us-east-1.amazonaws.com/default/ai-sales-assistant-bedrock-doc-add";

// export function parseFiles(files: File[], links: string[], descriptions: string[]): FileSaved[] {
//   return files.map((file, index) => ({
//     name: file.name,
//     link: links?.[index] || "",
//     description: descriptions?.[index] || "",
//     createdAt: new Date().toISOString(),
//     updatedAt: new Date().toISOString(),
//   }));
// }

// const AI_KNOWLADGE_BASE_ITEMMS_LOCAL_STORAGE_KEY = "ai-assistant-knowledge-base-items";
// const FILES_LOCAL_STORAGE_KEY = "ai-assistant-files";

export const useAiAssistantContext = () => {
  const context = useContext(AiAssistantContext);
  if (!context) {
    throw new Error("useAiAssistantContext must be used within a AiAssistantProvider");
  }
  return context;
};

const AiAssistantProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <AiAssistantContext.Provider
      value={{
        ...useAiAssistant(),
      }}
    >
      {children}
    </AiAssistantContext.Provider>
  );
};

const api = {
  uploadFiles: async ({
    files,
    links,
    descriptions,
  }: {
    files: File[];
    links: string[];
    descriptions: string[];
  }): Promise<void> => {
    const formData = new FormData();

    for (let index = 0; index < files.length; index++) {
      formData.append(`file_${index}`, files[index] as File);
      formData.append(`confluence_link_${index}`, links[index] as string);
      formData.append(`description_${index}`, descriptions[index] as string);
    }

    // console.log(formData);

    // return await new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve();
    //   }, 1000);
    // });

    return await axios.post(AI_ASSISTANT_BASE_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        // "x-api-key": import.meta.env.VITE_AI_ASSISTANT_KEY,
        "x-api-key": AI_ASSISTANT_API_KEY,
      },
    });
  },
  editFile: async (newFileData: AiAssistantFile) => {
    const formData = new FormData();

    formData.append("filename", newFileData.file);
    formData.append("new_description", newFileData.description);
    formData.append("new_source_link", newFileData.url);

    return await axios.put<AiAssistantFile>(AI_ASSISTANT_BASE_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": AI_ASSISTANT_API_KEY,
      },
    });
  },
  getStatus: async () => {
    return await axios.get<{ task_status: AiAssistantStatusResp }>(AI_ASSISTANT_BASE_URL + "/status", {
      headers: {
        "x-api-key": AI_ASSISTANT_API_KEY,
      },
    });
  },
  getFiles: async () => {
    return await axios.get<AiAssistantFile[]>(AI_ASSISTANT_BASE_URL, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": AI_ASSISTANT_API_KEY,
      },
    });
  },
};

export const useAiAssistant = () => {
  const [files, setFiles] = useState<AiAssistantFile[] | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isGlobalFetching, setIsGlobalFetching] = useState<boolean>(false);
  const [isModelsProcesings, setIsModelsProcesings] = useState<boolean>(false);

  const getStatus = useCallback(async () => {
    try {
      const resp = await api.getStatus();
      setIsModelsProcesings(resp.data.task_status === "IN_PROGRESS" ? true : false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const addFiles = useCallback(
    async ({
      addedFiles,
      descriptions = [],
      links = [],
      onFileLoading,
    }: {
      addedFiles: File[];
      links: string[];
      descriptions: string[];
      onFileLoading?: (index: number, status: LoadingStatus) => void;
    }) => {
      try {
        for (let index = 0; index < addedFiles.length; index++) {
          onFileLoading?.(index, "loading");
        }
        await api.uploadFiles({ files: addedFiles, links, descriptions });
        for (let index = 0; index < addedFiles.length; index++) {
          onFileLoading?.(index, "success");
        }
      } catch (error) {
        console.error(error);
        for (let index = 0; index < addedFiles.length; index++) {
          onFileLoading?.(index, "faild");
        }
        throw error;
      }
    },
    []
  );

  const editFile = useCallback(
    async (newFileData: AiAssistantFile) => {
      try {
        setIsFetching(true);
        await api.editFile(newFileData);
        const newFiles = files ? files.map((f) => (f.file === newFileData.file ? newFileData : f)) : [];
        setFiles(newFiles);
        setIsFetching(false);
      } finally {
        setIsFetching(false);
      }
    },
    [files]
  );

  const deleteFile = useCallback(() => {
    // const newFiles = files ? files.filter((file) => file.name !== fileName) : [];
    // setFiles(newFiles);
    // localStorage.setItem(FILES_LOCAL_STORAGE_KEY, JSON.stringify(newFiles));
  }, []);

  const getFiles = useCallback(async () => {
    try {
      setIsFetching(true);
      const resp = await api.getFiles();
      setFiles(resp.data);
      setIsFetching(false);
    } catch (error) {
      toast.error("Error while fetching files");
      setIsFetching(false);
    }
  }, []);

  // const editFile = useCallback(
  //   (file: AiAssistantFile) => {
  //     const newFiles = files ? files.map((f) => (f.name === file.name ? file : f)) : [];
  //     setFiles(newFiles);
  //     localStorage.setItem(FILES_LOCAL_STORAGE_KEY, JSON.stringify(newFiles));
  //   },
  //   [files]
  // );

  return useMemo(
    () => ({
      files,
      isGlobalFetching,
      setIsGlobalFetching,
      isFetching,
      addFiles,
      deleteFile,
      getFiles,
      editFile,
      isModelsProcesings,
      getStatus,
    }),
    [files, isGlobalFetching, isFetching, addFiles, deleteFile, getFiles, editFile, isModelsProcesings, getStatus]
  );
};

export default AiAssistantProvider;
