import { useEffect } from "react";
import { useDashboardContext } from "./DashboardContext";
import { useNotificationsContext } from "../Notifications/NotificationContext";
import PlannedNotifications from "./components/PlannedNotifications";
import UpcomingNotifications from "./components/UpcomingNotifications";
import "./Dashboard.scss";
import { useUserContext } from "../Users/UserContext";
import { Navigate } from "react-router-dom";
import Loader from "@/base/Loader";
import Setup from "./Setup";
import { useDestinationsContext } from "../Destinations";
import HealthCheckPlannedNotifications from "./components/HealthCheckPlannedNotifications";

const Dashboard = () => {
  const { dashboard, getDashboard, isFetching } = useDashboardContext();
  useNotificationsContext();
  const { isSuperAdmin, isOrgManager } = useUserContext();
  const { destinations, getDestinations } = useDestinationsContext();

  useEffect(() => {
    getDestinations();
    getDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**   
    const renderUpdateDateMessage = (dateStr: string) => {
    const date = dayjs(dateStr);

    const severity = dayjs().subtract(7, "day").isBefore(date) ? "success" : "warn";
    const text = date.format("DD MMM YYYY, HH:mm");

    return <Message severity={severity} text={text} className="ml-3" />;
  }; 
  */

  if (isSuperAdmin) {
    return <Navigate to={"/users"} state={{ returnUrl: window.location.pathname }} replace />;
  }

  /**
  if (destinations?.length === 0 && user?.formattedOrgSettings.slack_channel_id) {
    return <Setup step={2} />;
  } 
  */

  if (destinations && destinations?.length === 0) {
    return <Setup />;
  }

  return (
    <div className="flex flex-col gap-4">
      <Loader isLoading={isFetching} />
      <div className="flex justify-between">
        <span className="text-[40px]">Dashboard</span>
      </div>
      <div className="flex flex-col gap-6">
        {isOrgManager && <HealthCheckPlannedNotifications />}
        <PlannedNotifications notifications={dashboard?.todayGreetings ?? []} />
        <UpcomingNotifications notifications={dashboard?.upcomingGreetings ?? []} />
      </div>
    </div>
  );
};

export default Dashboard;
