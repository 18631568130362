import { showError } from "@/utils";
import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { classNames } from "primereact/utils";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTalksContext } from "../TalksContext";

const ImportTtnsFromCsvModal = ({ onClose }: { onClose: () => void }) => {
  const { id } = useParams();
  const { importTtnsFromCsv, getTtns, isFetching } = useTalksContext();

  const onSubmit = async (values: { importf?: File }) => {
    try {
      if (!values?.importf) {
        console.error("No file selected");
        toast.error("No file selected");
        return;
      }

      if (!id) {
        console.error("No talk selected");
        toast.error("No talk selected");
        return;
      }

      const body = new FormData();
      body.append("importf", values.importf);

      await importTtnsFromCsv(id, body);
      await getTtns(id);
      toast.success("Tracking Numbers imported");
      onClose();
    } catch (e) {
      showError(e, "Error importing Tracking Numbers from CSV");
    }
  };

  const renderFileName = (
    file: File,
    setFieldValue: (field: string, value: string | undefined, shouldValidate?: boolean | undefined) => void
  ) => (
    <div className={classNames("card-container yellow-container flex flex-wrap items-center")}>
      {!!file && (
        <Button
          className="  mr-2 flex"
          icon="pi pi-trash"
          onClick={(event) => {
            event.preventDefault();
            setFieldValue("importf", undefined);
          }}
        />
      )}
      <span className="  flex">{file.name}</span>
    </div>
  );

  return (
    <Dialog header={"Import Tracking Numbers from CSV"} visible={true} onHide={onClose}>
      <Formik<{
        importf?: File;
      }>
        initialValues={{
          importf: undefined,
        }}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="flex flex-col gap-4">
              <span className="">CSV content example</span>
              <div className="flex flex-col gap-1">
                <code className="font-mono text-gray-500">Email, Tracking number, Provider</code>
                <code className="text-gray-500">joe.smith@myorg.com,123123123123, NovaPoshta</code>
              </div>

              <div className="flex items-center gap-2 py-4">
                {!values.importf && (
                  <FileUpload
                    auto
                    customUpload
                    mode="basic"
                    name="importf"
                    accept="text/csv"
                    chooseLabel={"Select CSV file"}
                    uploadHandler={({ files, options }) => {
                      if (!files || files.length === 0) {
                        options.clear();
                        return;
                      }

                      if (files[0]?.type !== "text/csv") {
                        toast.error("Only CSV files are allowed");
                        options.clear();
                        return;
                      }

                      setFieldValue("importf", files[0]);
                    }}
                  />
                )}
                <span className="flex items-center justify-center ">
                  {!!values.importf && renderFileName(values.importf, setFieldValue)}
                </span>
              </div>
              <div className="flex justify-end gap-2 text-right">
                <Button label="Cancel" icon="pi pi-times" onClick={onClose} className="p-button-text" />
                <Button
                  label={"Start Import"}
                  icon="pi pi-check"
                  type="submit"
                  loading={isFetching}
                  disabled={!values?.importf}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ImportTtnsFromCsvModal;
