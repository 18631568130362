import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { ModalOperationType } from "../../types";
import Loader from "@/base/Loader";
import PageTitleWrapper from "@/components/PageTitleWrapper";
import { useUserContext } from "../Users/UserContext";
import TalkAddModal from "./TalkAddModal";
import { useNavigate } from "react-router-dom";
import { useTalksContext } from "./TalksContext";
import { Talk } from "./types";
import { TalkTypeReadble } from "./constants";
import Edit from "@/assets/edit.svg?react";
import Trash from "@/assets/trash.svg?react";
import clsx from "clsx";
import { confirmPopup } from "primereact/confirmpopup";
import { toast } from "react-toastify";
import { errorOrDefault } from "@/utils";

const TalksList = () => {
  const [showModal, setShowModal] = useState<ModalOperationType>(null);
  const navigate = useNavigate();

  const { getTalks, talks, isFetching, deleteTalk } = useTalksContext();
  const { isChannelManager } = useUserContext();

  useEffect(() => {
    getTalks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmDelete = (id: string) => {
    const confirmationMessageRef = document.getElementById("confirmMessage");
    if (!confirmationMessageRef) {
      console.error("No confirmationMessageRef found");
      return;
    }

    confirmPopup({
      target: confirmationMessageRef,
      message: "Are you sure you want to proceed?",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await deleteTalk(id);
          await getTalks();
          toast.success("Talk deleted successfully");
        } catch (e) {
          console.error(e);
          toast.error(errorOrDefault(e, "Error deleting talk"));
        }
      },
      reject: () => null,
    });
  };

  return (
    <div>
      <Loader isLoading={isFetching} />

      <PageTitleWrapper
        row1={
          <>
            <span className="text-[40px]">Talks</span>
          </>
        }
        row2={
          !isChannelManager && (
            <Button
              label="Add new talk"
              icon="pi pi-plus"
              onClick={() => {
                setShowModal("create");
              }}
              className="ml-5"
            />
          )
        }
      />

      <DataTable
        className="clickableRows"
        value={talks || []}
        onRowClick={(e) => {
          navigate(`/talks/${e.data.id}/ttns`);
        }}
      >
        <Column field="title" header="Title"></Column>
        <Column
          field="type"
          header="Type"
          body={(data: Talk) => {
            return <span>{TalkTypeReadble[data.type]}</span>;
          }}
        ></Column>
        <Column
          header="Operations"
          body={(data: Talk) => {
            return (
              <>
                <div className="flex gap-4 ">
                  <Edit className={clsx("", "cursor-pointer")} />
                  <Trash
                    className={clsx("", "cursor-pointer")}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      confirmDelete(data.id);
                    }}
                  />
                </div>
              </>
            );
          }}
        ></Column>
      </DataTable>
      {showModal && (
        <TalkAddModal
          onClose={() => {
            setShowModal(null);
          }}
          formType={showModal}
        />
      )}
    </div>
  );
};

export default TalksList;
