import AppMenuitem from "../../../base/AppMenuItem/AppMenuItem";
import { useUserContext } from "../../Users/UserContext";
import "./AppTopNavMenu.scss";

const AppTopNavMenu = () => {
  const { isSuperAdmin, isOrgManager, orgSettings } = useUserContext();

  const isCustomEventEnabled = orgSettings.feature_flag_shared_custom_event;

  let model = [
    { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/" },
    { label: "Channels", icon: "pi pi-fw pi-file-edit", to: "channels" },
    { label: "Team", icon: "pi pi-fw pi-users", to: "team" },
    { label: "Messages", icon: "pi pi-fw pi-file-edit", to: "messages" },
    { label: "Assets", icon: "pi pi-fw pi-file-edit", to: "gifs" },
  ];

  if (isSuperAdmin) {
    model = [
      { label: "AI Assistant", icon: "pi pi-fw pi-home", to: "/ai-assistant" },
      { label: "Organizations", icon: "pi pi-fw pi-file-edit", to: "orgs" },
      { label: "Org. Managers", icon: "pi pi-fw pi-file-edit", to: "users" },
      { label: "Events", icon: "pi pi-fw pi-file-edit", to: "events" },
      { label: "Prompts", icon: "pi pi-fw pi-file-edit", to: "prompts" },
      { label: "Messages", icon: "pi pi-fw pi-file-edit", to: "messages" },
      { label: "Assets", icon: "pi pi-fw pi-file-edit", to: "gifs" },
    ];
  }

  if (isOrgManager) {
    model = [
      { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/" },
      ...(orgSettings.feature_flag_health_check
        ? [
            {
              label: "Health Check",
              icon: "pi pi-fw pi-file-edit",
              to: "health-check",
            },
          ]
        : []),
      ...(orgSettings.feature_flag_ttn
        ? [
            {
              label: "Talks",
              icon: "pi pi-fw pi-file-edit",
              to: "talks",
            },
          ]
        : []),
      { label: "Channels", icon: "pi pi-fw pi-file-edit", to: "channels" },
      { label: "Channel Managers", icon: "pi pi-fw pi-file-edit", to: "users" },
      { label: "Team", icon: "pi pi-fw pi-users", to: "team" },
      ...(isCustomEventEnabled ? [{ label: "Events", icon: "pi pi-fw pi-file-edit", to: "events" }] : []),
      ...(isCustomEventEnabled ? [{ label: "Prompts", icon: "pi pi-fw pi-file-edit", to: "prompts" }] : []),
      { label: "Messages", icon: "pi pi-fw pi-file-edit", to: "messages" },
      { label: "Assets", icon: "pi pi-fw pi-file-edit", to: "gifs" },
    ];
  }

  return (
    <div
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="layout-menu flex gap-4"
    >
      {model.map((item) => (
        <AppMenuitem item={item} key={item.label} />
      ))}
    </div>
  );
};

export default AppTopNavMenu;
