import { useCallback } from "react";
import { IconUpload } from "@tabler/icons-react";
import { Button } from "primereact/button";
import { toast } from "react-toastify";

export type UploaderProps = {
  onAddFiles?: (files: File[]) => void;
  fileTypes: string[];
  fileTypesAccept: string;
  maxSizeMb?: number;
  initialFiles?: File[];
};

export default function Uploader({
  fileTypesAccept,
  maxSizeMb = 5,
  onAddFiles,
  initialFiles = [],
  fileTypes = [],
}: UploaderProps) {
  const handleAddFiles = useCallback(
    (newFiles: File[]) => {
      const validFiles = newFiles.filter((file) => [...fileTypes].includes(file.type));

      for (const file of validFiles) {
        if (file.size > maxSizeMb * 1024 * 1024) {
          toast.error(`File ${file.name} exceeds the maximum size of ${maxSizeMb} MB`);
        }
      }
      const newValidFiles = [...initialFiles, ...validFiles];
      onAddFiles?.(newValidFiles);
      const uploaderInput = document.getElementById("fileInput") as HTMLInputElement;
      if (uploaderInput) {
        uploaderInput.value = "";
      }
    },
    [fileTypes, initialFiles, maxSizeMb, onAddFiles]
  );

  const onDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const droppedFiles = Array.from(e.dataTransfer.files);
      handleAddFiles(droppedFiles);
    },
    [handleAddFiles]
  );

  const onFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.files) {
        const selectedFiles = Array.from(e.target.files);
        handleAddFiles(selectedFiles);
      }
    },
    [handleAddFiles]
  );

  return (
    <div className="w-[700px] px-6">
      <div
        onDragOver={onDragOver}
        onDrop={onDrop}
        className="flex h-[200px] cursor-pointer justify-between gap-4 rounded-lg border-2 border-dashed border-gray-800 px-5 text-center transition-colors hover:border-gray-400"
      >
        <div className="flex items-center gap-4 self-center">
          <IconUpload className="size-12 stroke-1 text-orange-500" />
          <div>
            <p className="mt-2 text-left text-white">Drag and drop files here</p>
            <p className="mt-1 text-left  text-gray-500">{`File formats: ${fileTypesAccept}`}</p>
            <p className="mt-1 text-left  text-gray-500">{`Max. file size: ${maxSizeMb} MB`}</p>
          </div>
        </div>
        <input
          type="file"
          multiple
          accept={fileTypesAccept}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onFileChange(e);
          }}
          className="hidden"
          id="fileInput"
        />
        <div className="flex flex-col gap-3 py-4">
          <div className="h-full w-1 self-center border border-gray-900"></div>
          <span className="text-gray-800">OR</span>
          <div className="h-full w-1 self-center border border-gray-900"></div>
        </div>
        <Button
          unstyled
          className="mt-4 self-center rounded-full border border-gray-800 px-4  py-2"
          onClick={() => document.getElementById("fileInput")?.click()}
        >
          Browse files...
        </Button>
      </div>
    </div>
  );
}
