import { PropsWithChildren, createContext, useContext, useState } from "react";
import { AssetDTO } from "./types";
import axios, { AxiosResponse } from "axios";

interface AssetContextProps {
  assets: AssetDTO[];
  isAssetsFetching: boolean;
  getAssets: (eventId?: string) => Promise<void>;
  addAsset: (values: FormData) => Promise<AxiosResponse>;
  deleteAsset: (id: string) => Promise<AxiosResponse>;
  alreadyUsed: number;
}

export const AssetContext = createContext(null as AssetContextProps | null);

export const useAssetContext = () => {
  const context = useContext(AssetContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
};

const AssetContextProvider = ({ children }: PropsWithChildren) => {
  const [assets, setAssets] = useState<AssetDTO[]>([]);
  const [alreadyUsed, setAlreadyUsed] = useState<number>(0);
  const [isAssetsFetching, setIsAssetsFetching] = useState<boolean>(false);

  const deleteAsset = async (id: string) => {
    setIsAssetsFetching(true);
    return await axios
      .delete(`/asset/${id}`)
      .catch((error) => {
        console.error("There was an error deleting the asset!", error);
        throw error;
      })
      .finally(() => {
        setIsAssetsFetching(false);
      });
  };

  const getAssets = async (eventId?: string) => {
    setIsAssetsFetching(true);
    return await axios
      .get<{ assets: AssetDTO[]; alreadyUsed: number }>("/assets", {
        params: {
          eventId,
        },
      })
      .then((res) => {
        const data = res.data;
        setAssets(data.assets);
        setAlreadyUsed(data.alreadyUsed);
      })
      .finally(() => {
        setIsAssetsFetching(false);
      });
  };

  const addAsset = async (values: FormData) => {
    setIsAssetsFetching(true);

    return await axios
      .post("/asset", values)
      .catch((error) => {
        console.error("There was an error uploading the file!", error);
        throw error;
      })
      .finally(() => {
        setIsAssetsFetching(false);
      });
  };

  return (
    <AssetContext.Provider
      value={{
        getAssets,
        addAsset,
        isAssetsFetching,
        assets,
        alreadyUsed,
        deleteAsset,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};

export default AssetContextProvider;
