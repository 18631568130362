import Loader from "@/base/Loader";
import { Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { useCallback, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { errorOrDefault, getFormikFromRef } from "@/utils";
import { useAiAssistantContext } from "./AiAssistantContext";
import Trash from "@/assets/trash.svg?react";
import { IconCheck, IconX } from "@tabler/icons-react";
import { Button } from "primereact/button";
import JInputText from "@/base/JInputText";
import Uploader from "@/components/Uploader";
import { AI_ASSISTANT_VALID_FILE_TYPES, AI_ASSISTANT_VALID_FILE_TYPES_ACCEPT } from "@/constants";
import SmallRoundLoadingSpinner from "@/components/SmallRoundLoadingSpinner";
import { Tooltip } from "primereact/tooltip";

type AiAssistentKnowladgeBaseForm = {
  links: string[];
  descriptions: string[];
};

type FormikProps = AiAssistentKnowladgeBaseForm;

export type LoadingStatus = "faild" | "success" | "loading" | "idle";

const AiAssistantAddKnowledgeBaseDialog = ({ onClose }: { onClose: () => void }) => {
  const { isFetching, setIsGlobalFetching, ...aiAssistantContext } = useAiAssistantContext();
  const [files, setFiles] = useState<File[]>([]);
  const [fileLoadings, setFileLoadings] = useState<LoadingStatus[]>([]);
  const formikRef = useRef(null);

  const handleSetFiles = (newFiles: File[]) => {
    setFiles(newFiles);
    setFileLoadings(newFiles.map(() => "idle"));
  };

  const onSubmit = async (values: FormikProps) => {
    try {
      setIsGlobalFetching(true);
      await aiAssistantContext.addFiles({
        addedFiles: files,
        links: values.links,
        descriptions: values.descriptions,
        onFileLoading: (index: number, status: LoadingStatus) =>
          setFileLoadings((prev) => prev.map((_, i) => (i === index ? status : _))),
      });
      toast.success("File(s) added");
      setTimeout(() => {
        onClose();
      }, 1000);
      setTimeout(() => {
        aiAssistantContext.getFiles();
        setIsGlobalFetching(false);
      }, 3000);
      return;
    } catch (err) {
      toast.error(errorOrDefault(err, "Error while adding file(s)"));
      setIsGlobalFetching(false);
    }
  };

  const footerContent = (dirty: boolean) => (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={() => onClose()} className="p-button-text" />
      <Button
        disabled={!dirty}
        label={"Add"}
        icon="pi pi-check"
        onClick={() => {
          const formik = getFormikFromRef(formikRef);
          formik.submitForm();
        }}
        loading={isFetching}
      />
    </div>
  );

  const removeFile = useCallback(
    (fileToRemove: File) => {
      const index = files.indexOf(fileToRemove);

      if (index === -1) return;

      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
      setFileLoadings((prevFileLoadings) => prevFileLoadings.filter((_, i) => i !== index));

      const formik = getFormikFromRef<AiAssistentKnowladgeBaseForm>(formikRef);
      formik.setFieldValue(
        "links",
        formik.values.links.filter((_, i) => i !== index)
      );
      formik.setFieldValue(
        "descriptions",
        formik.values.descriptions.filter((_, i) => i !== index)
      );
    },
    [files]
  );

  const renderDescriptions = useMemo(() => {
    if (!formikRef?.current) return [];

    const formik = getFormikFromRef<AiAssistentKnowladgeBaseForm>(formikRef);

    return (
      files.map((_, index) => {
        const description = formik?.values?.["descriptions"] as string[];

        if (description[index] === undefined) {
          formik.setFieldValue("descriptions", [...description, ""]);
        }

        return (
          <JInputText className="m-0" id={`descriptions.${index}`} noLabel title="" key={`descriptions.${index}`} />
        );
      }) || []
    );
  }, [files]);

  const renderLinks = useMemo(() => {
    if (!formikRef?.current) return [];

    const formik = getFormikFromRef<AiAssistentKnowladgeBaseForm>(formikRef);

    return (
      files.map((_, index) => {
        const description = formik?.values?.["links"] as string[];

        if (description[index] === undefined) {
          formik.setFieldValue("links", [...description, ""]);
        }

        return <JInputText className="m-0" id={`links.${index}`} noLabel title="" key={`links.${index}`} />;
      }) || []
    );
  }, [files]);

  return (
    <Formik<FormikProps>
      innerRef={formikRef}
      initialValues={{
        links: [],
        descriptions: [],
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validate={(values) => {
        const errors: Record<string, string | string[]> = {};

        for (let i = 0; i < files.length; i++) {
          if (!values.links[i]) {
            if (!errors[`links`]) {
              errors[`links`] = [];
            }
            (errors[`links`] as string[])[i] = "Required";
          }
        }

        return errors;
      }}
    >
      {({ dirty }) => (
        <Dialog header={"Add file(s)"} visible={true} onHide={onClose} footer={footerContent(dirty)}>
          <Loader isLoading={isFetching} />
          <div className="flex items-center justify-center pt-4">
            <Uploader
              fileTypesAccept={AI_ASSISTANT_VALID_FILE_TYPES_ACCEPT}
              fileTypes={AI_ASSISTANT_VALID_FILE_TYPES}
              initialFiles={files}
              onAddFiles={handleSetFiles}
              maxSizeMb={5}
            />
          </div>
          <div className="mx-auto flex w-[700px] items-center justify-between px-6">
            {files.length > 0 && (
              <div className="mt-5 w-full">
                <h3 className="mb-3 w-full font-bold">Uploaded Files</h3>
                <table className="w-full">
                  <thead>
                    <tr>
                      <td>
                        <span className="text-gray-500">File name</span>
                      </td>
                      <td>
                        <span className="text-gray-500">Link</span>
                      </td>
                      <td>
                        <span className="text-gray-500">Desicription</span>
                      </td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file, index) => (
                      <tr key={index} className="h-[60px]">
                        <td className="pr-2">
                          <span className="block pb-2 text-sm">{file.name}</span>
                        </td>
                        <td className="pr-4">
                          <span className="text-sm">{renderLinks[index]} </span>
                        </td>
                        <td className="pr-4">
                          <span className="text-sm">{renderDescriptions[index]}</span>
                        </td>
                        <td>
                          {fileLoadings[index] === "idle" && (
                            <Trash onClick={() => removeFile(file)} className="size-7 cursor-pointer stroke-1" />
                          )}
                          {fileLoadings[index] === "faild" && (
                            <div className="flex items-center gap-2">
                              <Tooltip
                                target={`#uploadingError`}
                                position="top"
                                content="Something went wrogn while file uplading"
                              />
                              <IconX id="uploadingError" className="size-6 rounded-full bg-red-500 text-gray-900" />
                              <Trash onClick={() => removeFile(file)} className="size-7 cursor-pointer stroke-1" />
                            </div>
                          )}
                          {fileLoadings[index] === "success" && (
                            <IconCheck className="size-6 rounded-full bg-green-500 text-gray-900" />
                          )}
                          {fileLoadings[index] === "loading" && <SmallRoundLoadingSpinner />}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Dialog>
      )}
    </Formik>
  );
};

export default AiAssistantAddKnowledgeBaseDialog;
