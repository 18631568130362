import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { ModalOperationType } from "../../types";
import { useUserContext } from "../Users/UserContext";
import Loader from "@/base/Loader";
import { confirmPopup } from "primereact/confirmpopup";
import { toast } from "react-toastify";
import Edit from "@/assets/edit.svg?react";
import Trash from "@/assets/trash.svg?react";
import { ChannelDTO } from "./types";
import ChannelAddEditModal from "./ChannelAddEditModal";
import { Tooltip } from "primereact/tooltip";
import PageTitleWrapper from "@/components/PageTitleWrapper";
import { getTimezoneTitleWithOffset } from "@/utils";

const ChannelList = () => {
  const [showModal, setShowModal] = useState<ModalOperationType>(null);
  const [curModalData, setCurModalData] = useState<ChannelDTO | null>(null);

  const { getChannels, deleteChannel, isChannelFetching, channels, user, syncChannelMembers } = useUserContext();

  const isChannelManager = user?.type === "USERS";

  const isChannelCreatingAllowed = true;

  useEffect(() => {
    getChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirm = (id: string) => {
    const confirmationMessageRef = document.getElementById("confirmMessage");
    if (!confirmationMessageRef) {
      console.error("Confirmation message is not defined");
      return;
    }
    confirmPopup({
      target: confirmationMessageRef,
      message: "Are you sure you want to proceed?",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await deleteChannel(id);
          toast.success("Channel deleted");
        } catch (err) {
          console.error(err);
          toast.error("Error deleting channel");
        } finally {
          await getChannels();
        }
      },
      reject: () => null,
    });
  };

  const operationsBodyTemplate = (rawData: ChannelDTO) => (
    <>
      <div className="flex items-center justify-end gap-4">
        <Tooltip target=".pi.pi-refresh" position="bottom" content="Sync Slack members IDs with Team" />
        <i
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="pi pi-refresh cursor-pointer text-[#B5B5B5]"
          onClick={() => {
            try {
              syncChannelMembers(rawData.id);
              toast.success("Channel members are being synced");
            } catch (err) {
              console.error(err);
              toast.error("Error syncing channel members");
            }
          }}
        ></i>

        <Edit
          className="cursor-pointer"
          onClick={() => {
            setCurModalData(rawData);
            setShowModal("update");
          }}
        />

        {!isChannelManager && <Trash className="cursor-pointer" onClick={() => confirm(rawData.id)} />}
      </div>
    </>
  );

  return (
    <div>
      <Loader isLoading={isChannelFetching} />
      <PageTitleWrapper
        row1={
          <>
            <span className="text-[40px]">Channels</span>
          </>
        }
        row2={
          !isChannelManager && (
            <Button
              tooltip={!isChannelCreatingAllowed ? "All channel are already created" : ""}
              tooltipOptions={{ position: "left" }}
              label="Add new channel"
              icon="pi pi-plus"
              onClick={() => {
                if (!isChannelCreatingAllowed) return;

                setShowModal("create");
              }}
              className="ml-5"
            />
          )
        }
      />
      <DataTable value={channels || []}>
        <Column field="channelTitle" header="Channel Title"></Column>
        <Column
          field="timezone"
          header="Timezone"
          body={(row) => {
            return getTimezoneTitleWithOffset(row.timezone);
          }}
        ></Column>
        <Column
          field="isGroup"
          header="Grouped"
          body={(rawData) => {
            if (rawData?.isNotificationsGrouped) {
              return "Yes";
            }
            return <span className="text-gray-500">No</span>;
          }}
        ></Column>
        <Column
          field="useTemplatesForGroupCongrats"
          header="Templates"
          body={(row) => {
            if (row.useTemplatesForGroupCongrats) {
              return "Yes";
            }
            return <span className="text-gray-500">No</span>;
          }}
        ></Column>
        <Column
          field="usePersonalizedMessage"
          header="Personalized Messages"
          body={(row) => {
            if (row.usePersonalizedMessage) {
              return "Yes";
            }
            return <span className="text-gray-500">No</span>;
          }}
        ></Column>
        <Column
          header="Notification time"
          body={(row: ChannelDTO) => {
            return `${row.notifyFromHour.toString().padStart(2, "0")}:${row.notifyFromMin.toString().padStart(2, "0")} - ${row.notifyToHour.toString().padStart(2, "0")}:${row.notifyToMin.toString().padStart(2, "0")}`;
          }}
        ></Column>
        <Column
          header="Queue re-generate time"
          body={(row: ChannelDTO) => {
            return `${row.notifyUpdateHour.toString().padStart(2, "0")}:${row.notifyUpdateMin.toString().padStart(2, "0")}`;
          }}
        ></Column>
        <Column field="description" header="Description"></Column>
        <Column header="Operations" body={operationsBodyTemplate}></Column>
      </DataTable>
      {showModal && (
        <ChannelAddEditModal
          onClose={() => {
            setShowModal(null);
            setCurModalData(null);
          }}
          data={curModalData}
          formType={showModal}
        />
      )}
    </div>
  );
};

export default ChannelList;
