import { allTimezones } from "react-timezone-select";

export const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

export const timezones = {
  ...allTimezones,
};

export const AI_ASSISTANT_VALID_FILE_TYPES = [
  "application/pdf",
  // "text/plain",
  // "application/msword",
  // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const AI_ASSISTANT_VALID_FILE_TYPES_ACCEPT = ".pdf";
